<template>
    <div>
        <div v-if="isRegisteredAdmin">
            <LoginBoxed></LoginBoxed>
        </div>
        <div v-else>
            <RegisterBoxed></RegisterBoxed>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import RegisterBoxed from "./Registration/RegisterBoxed"
    import LoginBoxed from "./Registration/LoginBoxed"

    export default {
        components: {RegisterBoxed, LoginBoxed},
        computed: {
            ...mapGetters({ 
                vacation: "employeeVacation" ,
                isAdmin: "isAdmin",
                isRegisteredAdmin: "isRegisteredAdmin"
            }),
        },
    }
</script>