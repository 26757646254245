<template>
    <div>
        <div class="h-100 bg-plum-plate bg-animation">
            <div class="d-flex h-100 justify-content-center align-items-center">
                <b-col md="8" class="mx-auto app-login-box">
                    <div class="app-logo-inverse mx-auto mb-3"/>

                    <div class="modal-dialog w-100 mx-auto">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="h5 modal-title text-center">
                                    <h4 class="mt-2">
                                        <div>С возвращением,</div>
                                        <span>Пожалуйста, авторизуйтесь ниже.</span>
                                    </h4>
                                </div>
                                <b-form-group id="exampleInputGroup1" label-for="exampleInput1"
                                              description="Мы никогда никому не передадим ваш email.">
                                    <b-form-input id="exampleInput1" type="email" placeholder="Введите email..." v-model="email" disabled >
                                    </b-form-input>
                                </b-form-group>
                                <b-form-group id="exampleInputGroup2" label-for="exampleInput2">
                                    <b-form-input id="exampleInput2" type="password" placeholder="Введите пароль..." v-model="password" required>
                                    </b-form-input>
                                </b-form-group>
                                <div class="divider"/>
                                <div v-if="isMessage"><h6 class="mb-0">{{message}}</h6></div>
                            </div>
                            <div class="modal-footer clearfix">
                                <div class="float-right">
                                    <b-button variant="primary" size="lg" v-on:click="LoginBackend">Login</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-white opacity-8 mt-3">
                        Copyright &copy; 2021 INCHKIEV
                    </div>
                </b-col>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                password: "",
                message: "",
                isMessage: false
            }
        },

        computed: {
            ...mapGetters({
                isRegisteredAdmin: "isRegisteredAdmin",
                email: "thatAdminEmail",
                username:"thatAdminUsername",
                getStatus: "getStatus"

            })
        },
        mounted(){
            if(this.getStatus === "success"){
                alert('Вже авторизовано!')
                this.$router.push('/adminvacation');
            }
        },
        methods: {
            LoginBackend() {
                this.isMessage = false;
                if (!this.password) {
                    this.message = "Введите, пожалуйста, пароль";
                    this.isMessage = true;
                } else if (this.password.length < 6) {
                    this.message = "Пароль должен содержать не менее 6 символов";
                    this.isMessage = true;
                } else {
                    const credentials = {
                        email: this.email,
                        username: this.username,
                        password: this.password
                    }
                    if (!this.isRegisteredAdmin) {
                        this.message = "Вначале нужно зарегистрироваться";
                        this.isMessage = true;
                    } else {
                        this.$store.dispatch('login', credentials)
                        .then(() => this.$router.push('/adminvacation'));
                    }
                    this.message = "Вы успешно вошли в систему";
                    this.isMessage = true;
                }
            },
        },
    }
</script>