<template>
    <div>
        <div class="h-100 bg-premium-dark">
            <div class="d-flex h-100 justify-content-center align-items-center">
                <b-col md="8" class="mx-auto app-login-box">
                    <div class="app-logo-inverse mx-auto mb-3"/>

                    <div class="modal-dialog w-100">
                        <div class="modal-content">
                            <div class="modal-body">
                                <h5 class="modal-title">
                                    <h4 class="mt-2">
                                        <div>Добро пожаловать,</div>
                                        <span>Нужно только <span class="text-success">несколько секунд</span>, чтобы зарегистрироваться</span>
                                    </h4>
                                </h5>
                                <div class="divider"/>
                                <b-form-group id="exampleInputGroup1" label-for="exampleInput1"
                                              description="Мы никогда никому не передадим ваш email.">
                                    <b-form-input id="exampleInput1" type="email" placeholder="Введите email..." required
                                                v-model="email" disabled>
                                    </b-form-input>
                                </b-form-group>
                                <b-form-group id="exampleInputGroup12" label-for="exampleInput12">
                                    <b-form-input id="exampleInput12" type="text" required placeholder="Введите свое имя..."
                                                v-model="username" disabled>
                                    </b-form-input>
                                </b-form-group>
                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group id="exampleInputGroup2" label-for="exampleInput2">
                                            <b-form-input id="exampleInput2" type="password" required placeholder="Введите пароль..."
                                                        v-model="password">
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                        <b-form-group id="exampleInputGroup2" label-for="exampleInput2">
                                            <b-form-input id="exampleInput2" type="password" required placeholder="Повторите пароль..."
                                            v-model="repeatpassword">
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="divider"/>
                                <div v-if="isMessage"><h6 class="mb-0">{{message}}</h6></div>
                            </div>
                            <div class="modal-footer d-block text-center">
                                <b-button color="primary" class="btn-wide btn-pill btn-shadow btn-hover-shine" size="lg" v-on:click="SignInBackend">
                                    Зарегистрироваться
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-white opacity-8 mt-3">
                        Copyright &copy; 2021 INCHKIEV
                    </div>
                </b-col>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                password: "",
                repeatpassword: "",
                message: "",
                isMessage: false
            }
        },

        computed: {
            ...mapGetters({
                isRegisteredAdmin: "isRegisteredAdmin",
                email: "thatAdminEmail",
                username:"thatAdminUsername",

            })
        },
        mounted(){
            if(this.$store.getters.isRegisteredAdmin){
                console.log('pushing to admin panel')
                console.log(this.$store.getters.isRegisteredAdmin);
                this.$router.push('/adminvacation')
            }
        },
        methods: {
            SignInBackend() {
                this.isMessage = false;
                if (!this.password) {
                    this.message = "Введите, пожалуйста, пароль";
                    this.isMessage = true;
                } else if (this.password.length < 6) {
                    this.message = "Пароль должен содержать не менее 6 символов";
                    this.isMessage = true;
                } else if (this.password != this.repeatpassword) {
                    this.message = "Подтверждение пароля не совпало с паролем";
                    this.isMessage = true;
                } else {
                    const credentials = {
                        email: this.email,
                        username: this.username,
                        password: this.password
                    }
                    if (!this.isRegisteredAdmin) {
                        console.log('dispatching...')

                        this.$store.dispatch('register', credentials)
                            .then(() => { this.$store.dispatch('login', credentials) })
                            .then(() => window.location.reload())
                            .catch(err => console.log(err));
                    } else {
                        this.$store.dispatch('login', credentials)
                        .then(() => this.$router.push('/adminvacation'));
                    }
                    this.message = "Вы успешно зарегистрировались и вошли в систему";
                    this.isMessage = true;
                }
            },
        },
    }
</script>
